import { type ButtonHTMLAttributes } from 'react'

import { css, styled } from '@mui/material'

export const StyledTickerContainer = styled('div', {
  shouldForwardProp: propName => propName !== 'isClickable'
})<
  {
    isClickable?: boolean
  } & ButtonHTMLAttributes<HTMLDivElement>
>`
  cursor: default;
  background-color: var(--PrimaryVariant);

  ${({ isClickable = true }) => {
    if (isClickable) {
      return css`
        cursor: pointer;

        :active {
          background-color: var(--PrimaryVariant);
        }
      `
    }
  }}
`

export const StyledTickerText = styled('div')`
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  user-select: none;

  a {
    pointer-events: none;
  }

  p {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
  }
`
