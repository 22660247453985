import { request } from '@elitecompetitions/client-api/lib/core/request'
import { OpenAPI } from '@elitecompetitions/client-api'

import requestV2 from '@services/api'

export const getUserTransactions = async ({
  page,
  limit
}: {
  page: number
  limit: number
}) => {
  const response = await request(OpenAPI, {
    method: 'GET',
    url: '/v1/transactions/my-transactions',
    query: {
      page,
      limit
    }
  })

  return response
}

export const getUserTransactionsByTransactionId = async (
  transactionId: string
) => {
  const response = await request(OpenAPI, {
    method: 'GET',
    url: '/v1/transactions/my-transactions',
    query: {
      transactionId
    }
  })

  // @ts-ignore
  return (response.data.data || [])[0]
}

export const getSavedCards = async (limit: number = 100) => {
  const { data } = await requestV2(`/users/saved-cards?limit=${limit}`, 'GET')

  return data
}

export const removeSavedCard = async (sourceId: string) => {
  await requestV2(`/users/saved-cards/${sourceId}`, 'DELETE', {
    data: {}
  })
}

export const storeUserTelemetry = async clientTelemetryData => {
  await requestV2('/users/storeUserTelemetry', 'POST', {
    data: {
      ...clientTelemetryData
    }
  })
}
